import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col  } from 'react-grid-system';
const FooterTitle = styled.h1`
color : white;
font-size : 18pt;
cursor : pointer;
`

const FooterContainer = styled.div`
background-color : black;
min-height : 60vh;
width : 100%;
text-align : center;
padding-top : 12vh;
`

function Footer() {
    return (
        <FooterContainer >
            <Container>
                <Row>
                    <Col >
                        <FooterTitle>InnovCreative</FooterTitle>
                    </Col>
                    <Col >
                        <FooterTitle>Contact</FooterTitle>
                    </Col>
                    <Col >
                        <FooterTitle>Services</FooterTitle>
                    </Col>
                </Row>
            </Container>
        </FooterContainer>
    );
}

export default Footer;