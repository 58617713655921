import React from 'react';
import styled from 'styled-components';


const PageContainer = styled.div`
    background-color : ${(props) => props.backgroundColor};
    display: flex;
	align-items: center;
	justify-content: center;
    min-height: ${(props) => props.minHeight + 'vh'};
`;

function Page(props) {
    return (
        <PageContainer backgroundColor={props.backgroundColor} minHeight={props.minHeight > 0 ? props.minHeight : 100}>
            {props.children}
        </PageContainer>
    );
}

export default Page;