import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const ICard = styled.div`
  background: white;
  color : black;
  margin : 10px;
  max-width : 400px;
  border-radius:20px;
  cursor : pointer;
  box-shadow: 0 0 10px rgba(0,0,0,.5);
  transition: all .5s;
  &:hover {
    box-shadow: 0 8px 8px rgba(0,0,0,.25);
    margin : 5px;
  }
`;

const CardTitle = styled.h1`
  color : black;
  font-size : 15pt;
  margin-top : 15px;
  text-align:center;
`;

const CardDescription = styled.p`
    color : black;
    font-size : 10pt;
    color : gray;
    padding : 18px 10px 18px 18px; 
    bottom : 0;
`;

const CardIcon = styled.div`
    color : #37a4eb;
    font-size: 40px;
    padding-top: 30px;
    text-align: center;
`;

function Card(props) {
    return (
        <ICard>
            <CardIcon>
                <FontAwesomeIcon icon={props.icon} />
            </CardIcon>
            <CardTitle>{props.title}</CardTitle>
            <CardDescription>{props.description}</CardDescription>
        </ICard>
    );
}

export default Card;