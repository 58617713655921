import React from 'react';
import styled from 'styled-components';


const BannerParent = styled.div`
background: url("/background-image-2k.png");
height : 100vh;
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
`;

function Banner() {
    return (
        <BannerParent />
    );
}

export default Banner;