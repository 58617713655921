import React from "react";
import styled from "styled-components";
import { useMenuContext } from "../../state";
import { useScrollFreeze } from "../../hooks";
import NavLinks from "./Links";

const MobileNavbar = () => {
  const { isMenuOpen } = useMenuContext();
  useScrollFreeze(isMenuOpen);
  return (
    <>
      {isMenuOpen && (
        <Mobile>
          <NavLinks />
        </Mobile>
      )}
    </>
  );
};

export default MobileNavbar;

const Mobile = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #0b4a8d !important;
  display: flex;
  justify-content: center;
  align-items: center;
`;