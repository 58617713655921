import React from "react";
import styled from "styled-components";
import Desktop from "./Desktop";
import Mobile from "./Mobile";

const Navbar = () => {
  return (
    <Nav>
      <Desktop />
      <Mobile />
    </Nav>
  );
};

export default Navbar;

const Nav = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;