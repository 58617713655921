import React from 'react';
import Page from '../page'
import Card from '../card'
import { faBug } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col } from 'react-grid-system';
function WeDo() {
    return (
        <div className="align">
            <Page backgroundColor="#fefefe">
                <Container>
                    <Row>
                        <Col sm={6}>
                            <Card icon="coffee" title="Software Testing" description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
                        </Col>
                        <Col sm={6}>
                            <Card icon={faBug} title="Software Testing" description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6}>
                            <Card icon="coffee" title="Devops" description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
                        </Col>
                        <Col sm={6}>
                            <Card icon={faBug} title="Software Testing" description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
                        </Col>
                    </Row>
                </Container>
            </Page>
            </div>
    );
}
export default WeDo;