import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useMenuContext } from "../../state";
import { Squash as Hamburger } from "hamburger-react";
import NavLinks from "./Links";
import { useScroll } from "../../hooks";

const DesktopNavbar = () => {
  const { isMenuOpen, toggleMenu } = useMenuContext();
  const { isScrolled } = useScroll();
  return (
    <Desktop isScrolled={isScrolled}>
      <Link to="/" className="logo">
        <div>
        <img src="/white-logo_300x170.png" alt="innovcreative" />
        </div>
      </Link>
      <NavLinks />
      <Hamburger toggled={isMenuOpen} toggle={toggleMenu} duration={0} />
    </Desktop>
  );
};

export default DesktopNavbar;

const Desktop = styled.nav`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  background: linear-gradient(122deg, rgba(53,168,224,1) 0%, rgba(29,112,183,1) 100%);
  color: var(--text);
  transition: all 150ms linear;
  box-shadow: var(--headerBoxShadow);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  padding: 0 60px;
  z-index: 2;
  @media screen and (max-width: 768px) {
    justify-content: space-between;
    padding: 0 30px;
  }
  .logo {
    flex: 2;
    color: var(--text);
    font-size: 32px;
  }
  .nav-links {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .hamburger-react {
    display: none;
    z-index: 99;
    & > div > div {
      background: var(--text) !important;
    }
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
`;